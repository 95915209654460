<script setup>
import {useBaseStore} from "~/stores/base";
import Modal from "~/components/Modal.vue";
import Drawer from "~/components/Drawer.vue";

const BNTOMessage = useNuxtApp().$BNTOMessage
const router = useRouter()
const route = useRoute()

/** emits **/
const emits = defineEmits([])

/** props **/
const props = defineProps({})

/** data **/

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const isShowModalShare = computed(() => useBaseStore().getterIsShowModalShare)
const data = computed(() => useBaseStore().getterShareData)

/** watch **/

/** methods **/
const handleClose = () => {
  useBaseStore().updateIsShowModalShare(false)
}
const handleLinkCopy = () => {
  const text = data.value.shareLink
  navigator.clipboard.writeText(text).then(() => {
    BNTOMessage.sparkle({
      title: "Link Copied!",
      message: "Now you can share the link to your friends!",
    })
    console.log("Text copied to clipboard!", text);
    // handleClose()
  }).catch((error) => {
    BNTOMessage.error({message: `Failed to copy text: ${error}`});
    console.error("Failed to copy text:", error);
    // handleClose()
  });
}
const handleSharePlatform = (platform) => {
  switch (platform) {
    case "instagram":
      // https://developers.facebook.com/docs/instagram-platform/sharing-to-feed?locale=zh_CN
      // window.open(`https://www.instagram.com/create/story`)
      window.open(`instagram://share?text=${data.value.shareInfo.instagram.shareContent}&url=${data.value.shareLink}`)
      break;
  }
  handleClose()
}
const handleMore = () => {
  if (navigator.share) {
    handleClose()
    navigator.share({
      title: data.value.shareInfo.other.shareTitle,
      text: data.value.shareInfo.other.shareContent,
      url: data.value.shareLink
    })
      .then(() => console.log('分享成功'))
      .catch(error => console.error('分享失败', error));
  } else {
    BNTOMessage.error({message: `Web Share API is unsupported`});
    console.log('Web Share API 不支持');
  }
}

/** lifecycle **/

</script>

<template>
  <div class="modal-share-mobile" v-if="isMobile">
    <Drawer
      :open="isShowModalShare"
      @close="handleClose"
      :btn-close-visible="true"
      :position="'bottom'"
      :border-radius="'25px 25px 0 0'"
      :z-index="4"
      :global-header-visible="false"
    >
      <div class="content">
        <div class="header">
          <div class="title">{{ data.popTitle }}</div>
        </div>
        <div class="body">
          <div class="text">{{ data.popDesc }}</div>
          <div class="platforms">
            <nuxt-link
              class="item"
              v-if="data.shareInfo.facebook"
              :to="`https://www.facebook.com/dialog/share?app_id=1218511205910392&href=${data.shareLink}`"
              aria-label="facebook"
              target="_blank"
            >
              <img class="icon" src="@/assets/img/icon-facebook-circle-58*58.svg" alt="facebook">
              <div class="name">{{ data.shareInfo.facebook.displayName }}</div>
            </nuxt-link>
            <div v-if="data.shareInfo.instagram" class="item" @click="handleSharePlatform('instagram')">
              <img class="icon" src="@/assets/img/icon-instagram-circle-58*58.svg" alt="instagram"/>
              <div class="name">{{ data.shareInfo.instagram.displayName }}</div>
            </div>
            <nuxt-link
              class="item"
              v-if="data.shareInfo.x"
              :to="`https://twitter.com/intent/tweet?text=${data.shareInfo.x.shareContent}&url=${data.shareLink}`"
              aria-label="x"
              target="_blank"
            >
              <img class="icon" src="@/assets/img/icon-x-circle-58*58.svg" alt="x">
              <div class="name">{{ data.shareInfo.x.displayName }}</div>
            </nuxt-link>
          </div>
          <div class="others">
            <div class="item" @click="handleLinkCopy">
              <div class="circle">
                <img class="icon" src="@/assets/img/icon-copy-link.svg" alt="icon"/>
              </div>
              <div class="name">Copy Link</div>
            </div>
            <div class="item" @click="handleMore">
              <div class="circle">
                <img class="icon" src="@/assets/img/icon-dots-gray.svg" alt="icon"/>
              </div>
              <div class="name">More</div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
  <div class="modal-share-desktop" v-else>
    <modal :open="isShowModalShare" @close="handleClose" :btn-close-visible="true">
      <div class="content">
        <div class="header">
          <div class="title">{{ data.popTitle }}</div>
          <div class="text">{{ data.popDesc }}</div>
        </div>
        <div class="share">
          <div class="link-container">
            <div class="link">{{ data.shareLink }}</div>
            <div class="btn-primary-small" @click="handleLinkCopy"><span>copy link</span></div>
          </div>
          <div class="platforms">
            <!-- https://developers.facebook.com/docs/sharing/reference/share-dialog -->
            <nuxt-link
              v-if="data.shareInfo.facebook"
              :to="`https://www.facebook.com/dialog/share?app_id=1218511205910392&href=${data.shareLink}`"
              aria-label="facebook"
              target="_blank"
            >
              <img class="icon" src="@/assets/img/icon-facebook-32*32.svg" alt="facebook">
            </nuxt-link>
            <img
              v-if="data.shareInfo.instagram"
              class="icon"
              src="@/assets/img/icon-instagram-32*32.svg" alt="icon"
              @click="handleSharePlatform('instagram')"
            />
            <!-- https://developer.x.com/en/docs/x-for-websites/tweet-button/overview -->
            <nuxt-link
              v-if="data.shareInfo.x"
              :to="`https://twitter.com/intent/tweet?text=${data.shareInfo.x.shareContent}&url=${data.shareLink}`"
              aria-label="x"
              target="_blank"
            >
              <img class="icon" src="@/assets/img/icon-x-32*32.svg" alt="x">
            </nuxt-link>
          </div>
        </div>
        <div class="footer">
          <div class="btn-secondary" @click="handleClose">done</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.modal-share-mobile {
  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .header {
      padding: 27px 16px;
      text-align: center;

      .title {
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
      }
    }

    .body {
      padding: 0 16px 16px 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .text {
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
      }

      .platforms {
        display: flex;
        gap: 16px;

        .item {
          flex-shrink: 0;

          .icon {
            width: 58px;
            height: 58px;
          }

          .name {
            margin-top: 8px;

            font-family: "TWK Lausanne";
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 12px; /* 120% */

            text-align: center;
          }
        }
      }

      .others {
        display: flex;
        gap: 16px;

        .item {
          flex-shrink: 0;

          .circle {
            width: 58px;
            height: 58px;
            border-radius: 50%;
            background-color: #F0F0F0;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: 38px;
              height: 38px;
            }
          }

          .name {
            margin-top: 8px;

            font-family: "TWK Lausanne";
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 12px; /* 120% */

            text-align: center;
          }
        }
      }
    }
  }
}

.modal-share-desktop {
  .content {
    width: 540px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .title {
        font-family: "Alternate Gothic No3 D";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 88.889% */
        letter-spacing: -0.36px;
        text-transform: uppercase;
        padding-top: 8px;
      }

      .text {
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
      }
    }

    .share {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .link-container {
        width: 100%;
        display: flex;

        .link {
          width: 100%;

          /* Text sm/Regular */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 142.857% */

          box-shadow: 0 0 0 .5px $color-BNTO-beige-dark;
          height: 36px;
          padding: 8px 12px;
        }

        .btn-primary-small {
          height: 36px;
          flex-shrink: 0;
          padding: 6px 8px;
        }
      }

      .platforms {
        display: flex;
        gap: 16px;

        .icon {
          cursor: pointer;
        }
      }
    }

    .footer {
      .btn-secondary {
        margin: 0 auto;
        width: 182px;
      }
    }
  }
}
</style>

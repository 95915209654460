import bodyApple from "@/assets/img/body-apple.svg"
import bodyPear from "@/assets/img/body-pear.svg"
import bodyHourglass from "@/assets/img/body-hourglass.svg"
import bodyStraight from "@/assets/img/body-straight.svg"
import bodyInvertedTriangle from "@/assets/img/body-inverted-triangle.svg"

export const APPID = "in299gcytlktvp3h"
export const APP_SECRET = "gp4vyuiaWTBBmfvc8S03brx2sHEs3FvZ4JJ6CnkDo8O"

export const NAME_ERROR_TIPS_MAP = {
  REQUIRED: "Name is required",
  ALPHABETIC_CHARACTERS_ONLY: "This field may only contain alphabetic characters."
}

export const EMAIL_ERROR_TIPS_MAP = {
  REQUIRED: "Email is required",
  FORMAT_ERROR: "Invalid Email address.",
  EMAIL_EXIST: "This email already exists. Please login."
}

export const MOBILE_ERROR_TIPS_MAP = {
  REQUIRED: "Mobile Number is required",
  FORMAT_ERROR: "Invalid Mobile Number.",
}

export const PASSWORD_ERROR_TIPS_MAP = {
  REQUIRED: "Password is required",
}

export const SUB_STATUS = {
  SUBSCRIBED: "Subscribed",
  PAUSED: "Paused",
  CANCELLED: "Cancelled"
}

export const SKU_STATUS = {
  NORMAL: 0,
  PURCHASED: 2,
  ISSUE_WITH_RETURN: 3,
  OUT_OF_STOCK: 5
}

export const BNTO_STATUS = {
  OPEN: "Open",
  FULL: "Full",
  REQUESTED: "Requested",
  INCOMING: "Incoming",
  PARTIALLY_INCOMING: "Partially Incoming",
  DELIVERED: "Delivered",
  PARTIALLY_DELIVERED: "Partially Delivered",
  RETURN_IN_TRANSIT: "Return in Transit",
  RETURNED: "Returned",
  UNRESOLVED: "Unresolved",
  PAUSED: "Paused",
  CANCELLED: "Cancelled",
  // GUEST: "Guest",
  // NOT_SUBSCRIBED: "Not Subscribed",
}

export const HAVING_BNTO_STATUS = {
  REQUESTED: "Requested",
  INCOMING: "Incoming",
  PARTIALLY_INCOMING: "Partially Incoming",
  DELIVERED: "Delivered",
  PARTIALLY_DELIVERED: "Partially Delivered",
  UNRESOLVED: "Unresolved",
}

export const FROM = {
  // normal
  SUBSCRIBE: "SUBSCRIBE",
  RESUBSCRIBE: "RESUBSCRIBE",
  // quick
  UNLOCK_EARLY: "UNLOCK_EARLY",
  RESUME_SUBSCRIPTION: "RESUME_SUBSCRIPTION",
  // others
  UNIT_PURCHASE: "UNIT_PURCHASE",
  BNTO_RENT: "BNTO_RENT",
}

export const BASIC_CONTENT_TYPE = {
  COLLECTION: "collection",
  CATEGORY: "category",
  SEARCH: "search",
}

export const SHARE_CONTENT_TYPE = {
  WISHLIST: "wishlist",
  ORDER: "order",
}

export const PATH_HOME = "/"
export const URL_HOME = import.meta.env.VITE_ORIGIN_WEBSITE
export const URL_RESET_PASSWORD = import.meta.env.VITE_ORIGIN_WEBSITE + "/reset-password"
export const PATH_RESET_PASSWORD = "/reset-password"
export const URL_REGISTER = import.meta.env.VITE_ORIGIN_WEBSITE + "/register"
export const PATH_REGISTER = "/register"
export const URL_SUBSCRIBE = import.meta.env.VITE_ORIGIN_WEBSITE + "/subscribe"
export const PATH_SUBSCRIBE = "/subscribe"
export const PATH_RESUBSCRIBE = "/resubscribe"
export const PATH_UNLOCK = "/unlock"
export const PATH_RESUME_SUBSCRIPTION = "/resume-subscription"
export const URL_ACCOUNT = import.meta.env.VITE_ORIGIN_WEBSITE + "/account"
export const PATH_ACCOUNT = "/account"
export const URL_ACCOUNT_ACCOUNT_OVERVIEW = import.meta.env.VITE_ORIGIN_WEBSITE + "/account/account-overview"
export const PATH_ACCOUNT_ACCOUNT_OVERVIEW = "/account/account-overview"
export const PATH_ACCOUNT_MY_PROFILE = "/account/my-profile"
export const PATH_ACCOUNT_RENTAL_HISTORY = "/account/rental-history"
export const URL_ACCOUNT_RENTAL_HISTORY = import.meta.env.VITE_ORIGIN_WEBSITE + "/account/rental-history"
export const PATH_ACCOUNT_MY_SUBSCRIPTION = "/account/my-subscription"
export const PATH_ACCOUNT_BILLING_AND_RECEIPTS = "/account/billing-and-receipts"
export const PATH_ACCOUNT_BONUS_CREDITS_AND_REFERRALS = "/account/bonus-credits-and-referrals"
export const URL_LOGIN = import.meta.env.VITE_ORIGIN_WEBSITE + "/login"
export const PATH_LOGIN = "/login"
export const URL_BRAND = import.meta.env.VITE_ORIGIN_WEBSITE + "/brand"
export const PATH_BRAND = "/brand"
export const URL_BRANDS = import.meta.env.VITE_ORIGIN_WEBSITE + "/brands"
export const PATH_BRANDS = "/brands"
export const PATH_NEW_ARRIVALS = "/new-arrivals"
export const URL_NEW_ARRIVALS = import.meta.env.VITE_ORIGIN_WEBSITE + "/new-arrivals"
export const PATH_OCCASIONS = "/occasions"
export const URL_OCCASIONS = import.meta.env.VITE_ORIGIN_WEBSITE + "/occasions"
export const PATH_PRODUCT = "/product"
export const URL_PRODUCT = import.meta.env.VITE_ORIGIN_WEBSITE + "/product"
export const PATH_NOT_FOUND = "/404"
export const PATH_EXPIRED = "/404#expired"
export const PATH_SERVER_ERROR = "/500"
export const PATH_COLLECTION = "/collection"
export const PATH_COLLECTION_ALL_STYLES = "/collection/allStyle?title=All+Styles"
export const PATH_CATEGORY = "/category"
export const URL_CATEGORY = import.meta.env.VITE_ORIGIN_WEBSITE + "/category"
export const PATH_SEARCH = "/search"
export const PATH_WISHLIST = "/wishlist"
export const URL_BNTO_LINKEDIN = "todo"
export const URL_BNTO_INSTAGRAM = "https://www.instagram.com/bnto.rent"
export const URL_BNTO_TIKTOK = "https://www.tiktok.com/@rentbnto"
// todo universal link
// export const URL_APP_DOWNLOAD = "https://testflight.apple.com/join/GmKgAhgm"
export const URL_APP_DOWNLOAD = "https://www.bnto.rent/download_app"
export const PATH_PAYMENT_RESULT_CHECK = "/payment-result-check"
export const URL_PAYMENT_RESULT_CHECK = import.meta.env.VITE_ORIGIN_WEBSITE + "/payment-result-check"

export const PATH_REVIEW_CREATE = "/review/create"
export const URL_REVIEW_CREATE = import.meta.env.VITE_ORIGIN_WEBSITE + "/review/create"

export const STATES = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
  "Connecticut", "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho",
  "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
  "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
  "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
  "New Hampshire", "New Jersey", "New Mexico", "New York",
  "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon",
  "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
  "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
  "West Virginia", "Wisconsin", "Wyoming"
]

export const SIZE_OPTIONS = [
  {
    "text": "XS \n US 0-0 \n W 24-25",
    "value": "XS"
  },
  {
    "text": "S \n US 2-4 \n W 26-27",
    "value": "S"
  },
  {
    "text": "M \n US 6-8 \n W 28-30",
    "value": "M"
  },
  {
    "text": "L \n US 8-10 \n W 30-32",
    "value": "L"
  },
  {
    "text": "XL \n US 10-12 \n W 33-34",
    "value": "XL"
  },
  {
    "text": "XXL \n US 14-16 \n W 35-37",
    "value": "XXL"
  }
]

export const BODY_SHAPE_OPTIONS = [
  {
    text: "Apple",
    img: bodyApple,
    value: "Apple"
  },
  {
    text: "Pear",
    img: bodyPear,
    value: "Pear"
  },
  {
    text: "Hourglass",
    img: bodyHourglass,
    value: "Hourglass"
  },
  {
    text: "Straight",
    img: bodyStraight,
    value: "Straight"
  },
  {
    text: "Inverted Triangle",
    img: bodyInvertedTriangle,
    value: "Inverted Triangle"
  },
]

export const FEET = [4, 5, 6]
export const INCHES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
export const MONTHS = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
export const YEARS = [
  1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979,
  1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989,
  1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
  2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022, 2023, 2024
];

export const TALK_STATUS = {
  LEISURE: 0,
  WAITING: 1,
  AI_TALKING: 2,
  ERROR: 3,
}

export const ROLE = {
  USER: "user",
  ASSISTANT: "assistant"
}

export const CARD_TYPE = {
  TEXT: "text",
  WAITING: "waiting",
  SYSTEM: "system",
  BIG_CARD: "bigCard",
  BNTO_BOX: "bntoBox",
  ARTICLE_CARD: "articleCard",
  ERROR: "error",
}

export const TRANSACTION_TYPE = {
  SUBSCRIPTION: "Subscription",
  PURCHASE: "Purchase",
  REFUND: "Refund",
  PREMIUM: "Premium"
}

export const PROGRESS_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
  ERROR: "error"
}

export const REGISTER_STEP = {
  CHOOSE_CHANNEL: "chooseChannel", // 1
  LOGIN_HISTORY_DISPLAY: "loginHistoryDisplay", // 2 third party - old user
  PASSWORD_CONFIRM: "passwordConfirm", // 2 third party - new user
  LOGIN_DETAILS: "loginDetails", // 2 email - register
  EMAIL_VERIFICATION: "emailVerification" // 3 email - verification
}

export const LOGIN_CHANNEL = {
  GOOGLE: "google",
  APPLE: "apple",
  FACEBOOK: "facebook"
}

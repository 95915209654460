import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function shareGenerate({resourceId, resourceType}) {
  return omniFetch({
    url: config.v1Path + config.shareGeneratePath,
    method: "post",
    params: {resourceId, resourceType}
  })
}

export function shareInfo({shareId}) {
  return omniFetch({
    url: config.v1Path + config.shareInfoPath,
    method: "post",
    params: {shareId}
  })
}

export function shareFilter({shareId}) {
  return omniFetch({
    url: config.v1Path + config.shareFilterPath,
    method: "post",
    params: {shareId}
  })
}

export function shareProductList({
                                   shareId,

                                   pageIndex,
                                   pageSize,

                                   availableNow,
                                   orderByColumn,
                                   filter,
}) {
  return omniFetch({
    url: config.v1Path + config.shareProductListPath + "/" + shareId,
    method: "post",
    params: {
      pageIndex,
      pageSize,
      availableNow,
      orderByColumn,
      filter,
    }
  })
}


